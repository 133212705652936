import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "bienvenido-a-aplazo---prestashop-docs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#bienvenido-a-aplazo---prestashop-docs",
        "aria-label": "bienvenido a aplazo   prestashop docs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bienvenido a Aplazo - Prestashop docs`}</h1>
    <p><strong parentName="p">{`Permita que sus clientes paguen a plazos de forma simple y fácil`}</strong>{`.`}</p>
    <p>{`Esta aplicación te permite conectar `}<strong parentName="p">{`Aplazo`}</strong>{` como medio de pago en tu tienda Prestashop. Con la siguiente documentación obtendrás la información necesaria para instalar y configurar `}<strong parentName="p">{`Aplazo`}</strong>{`.`}</p>
    <h2 {...{
      "id": "requisitos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#requisitos",
        "aria-label": "requisitos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requisitos`}</h2>
    <p>{`Para poder utilizar `}<strong parentName="p">{`Aplazo`}</strong>{` como medio de pago, es necesario que estés registrado como partner de `}<strong parentName="p">{`Aplazo`}</strong>{`. Si aún no lo estás, completa el siguiente `}<a href="https://cloud.em.aplazo.mx/partners-prestashop" target="_blank" style={{
        "color": "#3D72C9",
        "background": "url('/link.png') no-repeat 100% 0",
        "backgroundSize": "16px 16px",
        "paddingRight": "19px"
      }}><u>{` formulario`}</u></a>{` y un asesor comercial te contactará para darte de alta y brindarte las credenciales necesarias para la integración de `}<strong parentName="p">{`Aplazo`}</strong>{` a tu tienda Prestashop.`}</p>
    <p>{`Adicional, para el correcto funcionamiento será necesario contar con la instalación de Prestashop en su versión 1.6 o superior.`}</p>
    <h2 {...{
      "id": "versiones-del-módulo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#versiones-del-m%C3%B3dulo",
        "aria-label": "versiones del módulo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Versiones del módulo`}</h2>
    <p>{`Última versión estable: `}<a href="/modules/aplazo(16).zip" style={{
        "color": "#3D72C9",
        "background": "url('/link.png') no-repeat 100% 0",
        "backgroundSize": "16px 16px",
        "paddingRight": "19px"
      }}><u>{`Aplazo v1.6.3`}</u></a>{` `}</p>
    <br />
    <br />
    <br />
    <Link to="/instalacion" style={{
      padding: "7.5px 15px",
      backgroundColor: "#302E6A",
      color: "white",
      borderRadius: "5px"
    }} mdxType="Link">Inicia ahora</Link>
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      